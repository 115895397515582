import { FC } from 'react';
import { CourseType } from '@/controllers/graphql/generated';
import { CustomerWeekPromoBanner } from '@/components/landing/Promotions/CustomerWeekPromo/CustomerWeekPromoBanner';
import { FreeEnglishPromoBanner } from '@/components/landing/Promotions/FreeEnglishPromo/FreeEnglishPromoBanner';
import { FreeEnglishPromoCard } from '@/components/landing/Promotions/FreeEnglishPromo/FreeEnglishPromoCard';
import { LimitedPromoPLBanner } from '@/components/landing/Promotions/LimitedPromo/LimitedPromoPLBanner';
import { OnePlusOnePromoBanner } from '@/components/landing/Promotions/OnePlusOnePromo/OnePlusOnePromoBanner';
import { OnePlusOnePromoCard } from '@/components/landing/Promotions/OnePlusOnePromo/OnePlusOnePromoCard';
import { MonetaryPromoCard } from '@/components/landing/Promotions/MonetaryPromo/MonetaryPromoCard';
import { MonetaryPromoBanner } from '@/components/landing/Promotions/MonetaryPromo/MonetaryPromoBanner';
import { UnlimPromoCard } from '@/components/landing/Promotions/UnlimPromo/UnlimPromoCard';
import { UnlimPromoBanner } from '@/components/landing/Promotions/UnlimPromo/UnlimPromoBanner';
import { LimitedPromoBRBanner } from '@/components/landing/Promotions/LimitedPromo/LimitedPromoBRBanner';
import { LandingPromoCodeSlug, PageType } from '@/components/landing/Promotions/promotions.typedefs';
import { usePromotionsPromoCodesInfo } from '@/components/landing/Promotions/hooks/usePromotionsPromoCodesInfo.hook';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { OnePlusOnePromoBrBanner } from '@/components/landing/Promotions/OnePlusOnePromoBr/OnePlusOnePromoBrBanner';
import { DevopsWeekPromoBanner } from '@/components/landing/Promotions/DevopsWeekPromo/DevopsWeekPromoBanner';
import { useShouldShowPromo } from '@/components/landing/Promotions/hooks/useShouldShowPromo.hook';
import { checkIsWeekendToday } from '@/components/landing/Promotions/promotions.helpers';
import { SubDomains } from '@/constants/subdomains';
import { CustomerWeekPromoCard } from '../CustomerWeekPromo/CustomerWeekPromoCard';
import { SecretBonusPromoBanner } from '../SecretBonusPromo/SecretBonusPromoBanner';
import { DesignWeekPromoBanner } from '../DesignWeekPromo/DesignWeekPromoBanner';
import { IndependenceDayPlPromoBanner } from '../IndependenceDayPromoPl/IndependenceDayPromoPlBanner';

interface Options {
  courseType: CourseType;
  professionSlug?: string;
}

type Result = {
  PromoCard: FC<{ courseSlug: string }> | null;
  PromoBanner: FC<{
    courseSlug?: string;
    shouldShowRedesigned?: boolean;
  }> | null;
};

interface UseActivePromo {
  (options: Options): Result;
}

export const useActivePromo: UseActivePromo = (options) => {
  const features = useFeatures();
  const promoCodes = usePromotionsPromoCodesInfo();

  const shouldShowIndependenceDayPLPromoBanner = useShouldShowPromo({
    ...options,
    domain: SubDomains.pl,
    promoCode: promoCodes[LandingPromoCodeSlug.INDEPENDENCE_DAY_PROMO_PL],
    allowedCourseType: CourseType.Prepaid,
  });

  const shouldShowLimitedPromoPLBanner = useShouldShowPromo({
    ...options,
    domain: SubDomains.pl,
    promoCode: promoCodes[LandingPromoCodeSlug.LIMITED_OFFER_PL],
    allowedCourseType: CourseType.Prepaid,
    additionalCondition: !checkIsWeekendToday(),
  });

  const shouldShowLimitedPromoBRBanner = useShouldShowPromo({
    ...options,
    domain: SubDomains.br,
    promoCode: promoCodes[LandingPromoCodeSlug.LIMITED_OFFER_BR],
    allowedCourseType: CourseType.Prepaid,
    additionalCondition: !checkIsWeekendToday(),
  });

  const shouldShowCustomerWeekPromo = useShouldShowPromo({
    ...options,
    domain: SubDomains.br,
    promoCode: promoCodes[LandingPromoCodeSlug.CUSTOMER_WEEK_PROMO],
    allowedCourseType: CourseType.Prepaid,
  });

  const shouldShowFreeEnglishPromo = useShouldShowPromo({
    ...options,
    domain: SubDomains.ua,
    promoCode: promoCodes[LandingPromoCodeSlug.FREE_ENGLISH_PROMO],
    allowedCourseType: CourseType.Prepaid,
  });

  const shouldShowOnePlusOnePromo = useShouldShowPromo({
    ...options,
    domain: SubDomains.ua,
    promoCode: promoCodes[LandingPromoCodeSlug.ONE_PLUS_ONE_PROMO],
    allowedCourseType: CourseType.Prepaid,
    forbiddenProfessionSlugs: ['devops'],
  });

  const shouldShowMonetaryPromo = useShouldShowPromo({
    ...options,
    domain: SubDomains.ua,
    promoCode: promoCodes[LandingPromoCodeSlug.MONETARY_PROMO_UA],
    allowedCourseType: CourseType.Prepaid,
    allowedPageTypes: [PageType.COURSE, PageType.COURSES],
  });

  const shouldShowUnlimPromo = useShouldShowPromo({
    ...options,
    domain: SubDomains.ua,
    promoCode: promoCodes[LandingPromoCodeSlug.UNLIM_PROMO],
    allowedCourseType: CourseType.Prepaid,
  });

  const shouldShowSecretBonusPromo = useShouldShowPromo({
    ...options,
    domain: SubDomains.ua,
    promoCode: promoCodes[LandingPromoCodeSlug.SECRET_BONUS_PROMO],
  });

  const shouldShowDesignWeekPromo = useShouldShowPromo({
    ...options,
    domain: SubDomains.ua,
    promoCode: promoCodes[LandingPromoCodeSlug.DESIGN_WEEK_PROMO],
    allowedProfessionSlugs: ['ui-ux'],
  });

  const shouldShowOnePlusOnePromoBr = useShouldShowPromo({
    ...options,
    domain: SubDomains.br,
    promoCode: promoCodes[LandingPromoCodeSlug.ONE_PLUS_ONE_PROMO_BR],
  });

  const shouldShowDevopsWeekPromo = useShouldShowPromo({
    ...options,
    domain: SubDomains.ua,
    promoCode: promoCodes[LandingPromoCodeSlug.DEVOPS_WEEK_PROMO],
    allowedProfessionSlugs: ['devops'],
  });

  const shouldNotShowPromos = (
    features.isDisabled(features.LandingPromoBanners)
  );

  // ----- COMPONENTS -----
  if (shouldNotShowPromos) {
    return {
      PromoCard: null,
      PromoBanner: null,
    };
  }

  if (shouldShowIndependenceDayPLPromoBanner) {
    return {
      PromoCard: null,
      PromoBanner: IndependenceDayPlPromoBanner,
    };
  }

  if (shouldShowDevopsWeekPromo) {
    return {
      PromoCard: null,
      PromoBanner: DevopsWeekPromoBanner,
    };
  }

  if (shouldShowOnePlusOnePromoBr) {
    return {
      PromoCard: null,
      PromoBanner: OnePlusOnePromoBrBanner,
    };
  }

  if (shouldShowUnlimPromo) {
    return {
      PromoCard: UnlimPromoCard,
      PromoBanner: UnlimPromoBanner,
    };
  }

  if (shouldShowMonetaryPromo) {
    return {
      PromoCard: MonetaryPromoCard,
      PromoBanner: MonetaryPromoBanner,
    };
  }

  if (shouldShowOnePlusOnePromo) {
    return {
      PromoCard: OnePlusOnePromoCard,
      PromoBanner: OnePlusOnePromoBanner,
    };
  }

  if (shouldShowFreeEnglishPromo) {
    return {
      PromoCard: FreeEnglishPromoCard,
      PromoBanner: FreeEnglishPromoBanner,
    };
  }

  if (shouldShowCustomerWeekPromo) {
    return {
      PromoCard: CustomerWeekPromoCard,
      PromoBanner: CustomerWeekPromoBanner,
    };
  }

  if (shouldShowLimitedPromoPLBanner) {
    return {
      PromoCard: null,
      PromoBanner: LimitedPromoPLBanner,
    };
  }

  if (shouldShowLimitedPromoBRBanner) {
    return {
      PromoCard: null,
      PromoBanner: LimitedPromoBRBanner,
    };
  }

  if (shouldShowSecretBonusPromo) {
    return {
      PromoCard: null,
      PromoBanner: SecretBonusPromoBanner,
    };
  }

  if (shouldShowDesignWeekPromo) {
    return {
      PromoCard: null,
      PromoBanner: DesignWeekPromoBanner,
    };
  }

  return {
    PromoCard: null,
    PromoBanner: null,
  };
};
